/**
 * Rounds a number to 2 digits.
 *
 * @param num
 * @param fractionDigits
 */
export const roundNumber = (num, fractionDigits = 2) => {
    const number = (Math.round(num * 100) / 100).toFixed(fractionDigits);

    return formatNumber(number);
};

/**
 * Formats the given number with thousand seperators and the german comma.
 *
 * @param {*} num
 */
export const formatNumber = (num) => {
    const parts = num.toString().split(".");
    const number = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

    if (parts[1]) {
        return `${number},${parts[1]}`;
    }
    return number;
};

/**
 * Formats a Telephone-Number into either a Local Number or an international format.
 * @param number
 * @param asInternational
 * @param dialingCode
 * @returns {string}
 */
export const formatPhoneNumber = (
    number,
    asInternational = false,
    dialingCode = 49
) => {
    if (!number) {
        return "";
    }
    let plainNumber = String(number.replace(/[^0-9+]+/g, ""));

    if (asInternational && plainNumber.startsWith("0")) {
        return plainNumber.replace(/^0+/, `+${dialingCode}`);
    }
    if (!asInternational && plainNumber.startsWith(`+${dialingCode}`)) {
        plainNumber.replace(`/^+${dialingCode}+/`, "0");
    }
    return plainNumber;
};

/**
 * Converts a formatted number into a number, which can be calculated with.
 *
 * @param {*} num
 */
export const formattedToNumber = (num) => {
    return parseFloat(num.toString().replace(".", "").replace(",", "."));
};

export const formatNumberToLocale = (num, digits = 2, locale = "de-DE") => {
    let number = formattedToNumber(num);
    return number.toLocaleString(locale, { maximumFractionDigits: digits });
};

export const formatNumberToLocalePrice = (
    num,
    digits = 2,
    locale = "de-DE"
) => {
    return parseFloat(num).toLocaleString(locale, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
};
